export const getClosePath = (name: string, params: object) => {
  if (!name) { return }

  const paramsObject = params ? { params } : {}
  const route = useRoute()

  const query = useOmit({ ...route?.query, ...baseRouteGetPublicationStateQuery() }, 'participantId')

  return {
    name,
    ...paramsObject,
    query: query
  }
}
